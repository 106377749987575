<template>
  <div>
    <Breadcrumbs />
    <section class="customer-service">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-lg-9">
            <div class="tab-content no-gutters">
              <div class="tab-search col-12 col-md-4">
                <div class="form-group">
                  <input
                    type="text"
                    name="search-tab"
                    placeholder="Stel hier uw vraag…"
                    class="form-control"
                    @keyup="search"
                    @focus="handleFocus"
                  />
                  <div class="search-btn">
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
              <!-- Desktop -->
              <div class="tab-content custom-tab faq-tab" v-if="!mobileCheck">
                <b-tabs
                  pills
                  card
                  vertical
                  v-model="activeTab"
                  nav-wrapper-class="col-12 col-md-4 custom-tab-nav"
                  content-class="col-12 col-md-8 custom-tab-content"
                  @activate-tab="tabActivated"
                >
                  <b-tab title="search-result" title-link-class="d-none">
                    <div class="faq-title">{{ $t("search_result") }}</div>
                    <div v-if="filteredQuestions.length > 0">
                      <div
                        v-for="(filteredQuestion, index) in filteredQuestions"
                        :key="index"
                        class="questions-group"
                        @click="openSearchQuestion(index)"
                      >
                        <div class="faq-question">
                          {{ filteredQuestion.question }}
                        </div>
                        <div
                          v-html="
                            filteredQuestion.long_answer.replace(
                              /<[^>]+>/gi,
                              ' '
                            )
                          "
                          class="faq-answer short"
                        ></div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="no-data-found">
                        <p>{{ $t("no_data_found") }}</p>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab title="opened-que-result" title-link-class="d-none">
                    <div>
                      <div v-if="openedQuestion != null">
                        <div class="faq-title">
                          {{ openedQuestion.question }}
                        </div>
                        <div
                          v-html="openedQuestion.long_answer"
                          class="faq-answer"
                        ></div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab :title="$t('customer_service')">
                    <div class="question-box-wrapper">
                      <div class="faq-title">{{ $t("customer_service") }}</div>
                      <div
                        v-for="(faq, idx) in faqs"
                        :key="faq.category_id"
                        class="question-box"
                      >
                        <template v-if="faq.faq && faq.faq.items.length">
                          <b-link @click="goToCategory(idx + 3)">
                            <label>{{ faq.title }}</label>
                          </b-link>
                          <ul>
                            <li
                              v-for="(item, idx1) in faq.faq.items"
                              :key="'que-title' + idx1"
                              @click="openQuestion(faq.category_id, idx1)"
                            >
                              <!-- goToQuestion(
                                  idx + 3,
                                  `cat${faq.category_id}-que${idx1}`
                                ) -->
                              {{ item.question }}
                            </li>
                          </ul>
                        </template>
                      </div>
                    </div>

                    <!-- <cmsBlockSimple
                      v-if="typeof customerServiceCms == 'object'"
                      :identifier="customerServiceCms.identifier"
                    /> -->
                  </b-tab>

                  <template v-for="faq in faqs">
                    <b-tab :title="faq.title" :key="faq.category_id">
                      <div v-if="faq.faq && faq.faq.items.length">
                        <div class="faq-title">{{ faq.title }}</div>
                        <div
                          v-for="(item, idx) in faq.faq.items"
                          :key="idx"
                          class="questions-group"
                          @click="openQuestion(faq.category_id, idx)"
                        >
                          <div
                            :id="`cat${faq.category_id}-que${idx}`"
                            class="faq-question"
                          >
                            {{ item.question }}
                          </div>
                          <div
                            v-html="item.long_answer.replace(/<[^>]+>/gi, ' ')"
                            class="faq-answer short"
                          ></div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="no-data-found">
                          <p>{{ $t("no_data_found") }}</p>
                        </div>
                      </div>
                    </b-tab>
                  </template>
                </b-tabs>
              </div>
              <!-- Mobile -->
              <div v-else>
                <div class="accordion" role="tablist">
                  <div class="mob-customerService" v-if="activeTab === 0">
                    <div
                      class="customerService-title"
                      block
                      v-b-toggle.accordion-search
                      variant="info"
                    >
                      {{ $t("search_result") }}
                    </div>
                    <b-collapse
                      id="accordion-search"
                      accordion="my-accordion"
                      role="tabpanel"
                      visible
                    >
                      <b-card-body>
                        <b-card-text>
                          <div v-if="filteredQuestions.length">
                            <div
                              v-for="(
                                filteredQuestion, index
                              ) in filteredQuestions"
                              :key="index"
                            >
                              <div class="faq-question">
                                {{ filteredQuestion.question }}
                              </div>
                              <div
                                v-html="filteredQuestion.long_answer"
                                class="faq-answer"
                              ></div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="no-data-found">
                              <p>{{ $t("no_data_found") }}</p>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </div>

                  <div class="mob-customerService" v-if="activeTab === 1">
                    <div
                      class="customerService-title"
                      block
                      v-b-toggle.accordion-search
                      variant="info"
                    >
                      Opened Question
                    </div>
                    <b-collapse
                      id="accordion-search"
                      accordion="my-accordion"
                      role="tabpanel"
                      visible
                    >
                      <b-card-body>
                        <b-card-text>
                          <div v-if="filteredQuestions.length">
                            <div
                              v-for="(
                                filteredQuestion, index
                              ) in filteredQuestions"
                              :key="index"
                            >
                              <div class="faq-question">
                                {{ filteredQuestion.question }}
                              </div>
                              <div
                                v-html="filteredQuestion.long_answer"
                                class="faq-answer"
                              ></div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="no-data-found">
                              <p>{{ $t("no_data_found") }}</p>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </div>

                  <div
                    class="mob-customerService"
                    v-for="faq in faqs"
                    :key="faq.category_id"
                  >
                    <div
                      class="customerService-title"
                      block
                      v-b-toggle="'accordion' + faq.category_id"
                      variant="info"
                    >
                      {{ faq.title }}
                    </div>
                    <b-collapse
                      :id="'accordion' + faq.category_id"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <div v-if="faq.faq && faq.faq.items.length">
                            <div
                              v-for="(item, idx) in faq.faq.items"
                              :key="idx"
                            >
                              <div class="faq-question">
                                {{ item.question }}
                              </div>
                              <div
                                v-html="item.long_answer"
                                class="faq-answer"
                              ></div>
                            </div>
                          </div>
                          <div v-else>
                            <h1>
                              <div class="no-data-found">
                                <p>{{ $t("no_data_found") }}</p>
                              </div>
                            </h1>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-lg-3">
            <cmsBlockSimple
              v-if="typeof customerServiceImgCms == 'object'"
              :identifier="customerServiceImgCms.identifier"
            />
            <cmsBlockSimple
              v-if="typeof contactInfoCms == 'object'"
              :identifier="contactInfoCms.identifier"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs.vue";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import { isMobile, isTablet } from "mobile-device-detect";
export default {
  components: {
    Breadcrumbs,
    cmsBlockSimple,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },
        {
          name: "keywords",
          content: this.currentPage && (this.currentPage.meta_keywords ?? ""),
        },
        {
          name: "description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
        {
          property: "og:title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },
        {
          property: "og:description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
        {
          name: "twitter:title",
          content:
            this.currentPage &&
            (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
        },
        {
          name: "twitter:description",
          content:
            this.currentPage && (this.currentPage.meta_description ?? ""),
        },
      ],
      title:
        this.currentPage &&
        (this.currentPage.meta_title ?? this.currentPage.title ?? ""),
    };
  },
  data() {
    return {
      filteredQuestions: [],
      searchTimer: null,
      activeTab: 2,
      openedQuestion: null,
    };
  },
  mounted() {
    const bcrumb = {
      current: this.$t("customer_service"),
      routes: [],
    };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  computed: {
    faqs() {
      return this.$store.getters["faq/getFaq"];
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    customerServiceCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service"
      );
    },
    exchangeReturnCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "exchange_returns"
      );
    },
    customerServiceImgCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_image"
      );
    },

    contactInfoCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_contact_info"
      );
    },
    currentPage() {
      return this.$store.getters["cmsPage/getCurrentPage"];
    },
  },
  methods: {
    search(event) {
      let searchVal = event.target.value.trim();
      if (searchVal.length > 0) {
        searchVal = searchVal.toLowerCase();

        this.activeTab = 0;
        if (this.searchTimer !== null) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          let allQuestions = [];
          this.faqs.forEach((element) => {
            allQuestions = [...allQuestions, ...element.faq.items];
          });

          const filteredQuestions = allQuestions.filter(
            (element) =>
              element.question.toLowerCase().includes(searchVal) ||
              element.long_answer.toLowerCase().includes(searchVal)
          );
          this.filteredQuestions = filteredQuestions;
        }, 300);
      } else {
        this.activeTab = 2;
      }
    },
    handleFocus(event) {
      if (event.target.value != "") {
        this.activeTab = 0;
      }
    },
    goToCategory(activeTab) {
      this.activeTab = activeTab;
    },
    goToQuestion(activeTab, elementId) {
      this.activeTab = activeTab;

      setTimeout(() => {
        document.getElementById(elementId).scrollIntoView({
          block: "center",
        });
      }, 100);
    },
    openQuestion(catId, queId) {
      const category = this.faqs.find((el) => el.category_id === catId);
      this.openedQuestion = category.faq.items[queId];
      this.activeTab = 1;
    },
    openSearchQuestion(queId) {
      this.openedQuestion = this.filteredQuestions[queId];
      this.activeTab = 1;
    },
    tabActivated() {},
  },
  watch: {
    activeTab(newVal) {
      if (newVal !== 1) {
        this.openedQuestion = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
.customer-service {
  padding: 0 0 30px;
}
.tab-search {
  .search-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $primaryColor;
  }
  input {
    border-radius: 50px;
    background: #e6e6e6;
    border-color: #e6e6e6;
    padding: 10px 50px 10px 15px;
  }
}
.tab-content {
  @media (max-width: 767px) {
    padding: 30px 0;
  }
}
.faq-tab {
  .faq-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
.faq-answer {
  font-size: 14px;
  line-height: 20px;
}
.faq-question {
  font-weight: 600;
  font-size: 16px;
}
.faqsearch-results {
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
}
.mob-customerService {
  .no-data-found {
    min-height: 200px;
    p {
      margin-top: 0;
    }
  }
}
</style>
